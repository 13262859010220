/*
用于防止按钮二次点击
在el-button那里加上v-preventReClick即可，如<el-button type="primary" @click="onSave()" v-preventReClick>确 定</el-button>
*/
import Vue from 'vue'

const preventReClick = Vue.directive('preventReClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 1000)
            }
        })
    }
});

export { preventReClick }