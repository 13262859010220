<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"/>
  </div>
</template>

<script>
  export default {
    methods: {
      
    },
    mounted() {
      
    },
  };
</script>

<style>
  .el-card__header{
    padding: 18px 20px 6px 20px !important;
  }
</style>
