import * as dd from 'dingtalk-jsapi';



/**
 * @description 定时重置Token
 */
window.onRefreshToken = function(that) {
  var setRefresh = setInterval(() => {
    // 判断时间是否还存在
    if (!regNull(that.$GetKey('tokenTime')) && !regNull(that.$GetKey('accessToken'))) {

      // 时间正常，就开始计算时长
      let dateStart = new Date(JSON.parse(that.$GetKey('tokenTime')));
      let dateEnd = new Date();
      let dateVal = dateEnd.getTime() - dateStart.getTime();

      let tpToken = JSON.parse(that.$GetKey('accessToken'));
      let tpExpiresIn = regNull(tpToken.expiresIn) ? 3600 : tpToken.expiresIn; // 获取Token时效

      // 判断是否在时效内
      if (parseInt(parseInt(dateVal) / 1000) > (tpExpiresIn * 0.9)) {
        let objToken = JSON.parse(that.$GetKey('accessToken'));
        let tempData = {
          token: objToken.accessToken,
          refreshToken: objToken.refreshToken
        }
        ajaxRefreshToken(that, '/api/auth/refreshtoken', tempData, function(resData) {
          if (resData.data.data.success != false) {
            that.$SaveKey(resData.data.data.token);
            that.$SaveKey(new Date(), 'tokenTime');
          }
        });
      }
    } else {
      clearInterval(setRefresh);
      return false;
    }
  }, 60000);
}



/**
 * @description 获取用户信息
 * @param {Object} that
 */
window.onGetNowUser = function(that) {
  let tpUser = that.$GetKey(that.$cacheTeacher);
  if (regNull(tpUser)) {
    that.$RemoveKey();
    warning(that, "登录失效，请重新登陆！");
    var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
    setTimeout(function() {
      if (dd.env.platform !== "notInDingTalk") {
        that.$router.push({
          path: '/DingDing',
          query: {
            url: that.$router.history.current.fullPath
          },
        });
      } else {
        if (fullPath.indexOf("login?url=") < 0) {
          that.$router.push({
            path: '/login',
            query: {
              url: that.$router.history.current.fullPath
            },
          });
        } else {
          that.$router.push({
            path: that.$router.history.current.fullPath,
          });
        }
      }
      return false;
    }, 1500);
    return false;
  } else {
    return JSON.parse(tpUser);
  }
}

/**
 * @description 获取当前角色
 * @param {Object} that
 */
window.onGetNowRole = function(that) {
  let tpRole = that.$GetKey('nowRole');
  if (regNull(tpRole)) {
    that.$RemoveKey();
    warning(that, "登录失效，请重新登陆！");
    var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
    setTimeout(function() {
      if (dd.env.platform !== "notInDingTalk") {
        that.$router.push({
          path: '/DingDing',
          query: {
            url: that.$router.history.current.fullPath
          },
        });
      } else {
        if (fullPath.indexOf("login?url=") < 0) {
          that.$router.push({
            path: '/login',
            query: {
              url: that.$router.history.current.fullPath
            },
          });
        } else {
          that.$router.push({
            path: that.$router.history.current.fullPath,
          });
        }
      }
      return false;
    }, 1500);
    return false;
  } else {
    return JSON.parse(tpRole);
  }
}


/**
 * @description 页面定时刷新时间 （单位：分钟）
 */
window.onGetRegularRefresh = function() {
  let tpTime = webRegularRefresh();
  if (regNull(tpTime) || Number(tpTime) <= 0) {
    return 1;
  } else {
    return tpTime;
  }
}

/**
 * @description 询问提示框
 * @param {Object} that
 * @param {Object} msg
 * @param {Object} callback
 */
window.confirm = function(that, msg, callback) { //  判断是否确认删除
  that.$confirm(msg, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    callback(true);
  }).catch(() => {
    that.$message({
      type: 'info',
      message: '已取消'
    });
  });
}

// 系統登录
/**
 *
 * @param {Object} that
 * @param {Object} myUrl
 * @param {Object} data
 * @param {Object} callback
 */
window.ajaxDingDingLogin = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  that.$axios.get(
    url,
    data,
  ).then(res => {
    callback(res);
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl);
  });
};

/**
 * @description 删除确认弹出框
 * @param {Object} that
 * @param {Object} msg
 * @param {Object} callback
 */
window.confirmDelete = function(that, msg, callback) {
  if (msg == null || typeof(msg) == "undefined" || msg == "") {
    msg = "您确定要删除选中的信息吗？ 删除后信息将不可恢复，请知悉";
  }
  confirm(that, msg, callback);
}

/**
 * @description 以警告的样式显示提示语
 * @param {Object} that
 * @param {Object} msg
 */
window.warning = function(that, msg) {
  that.$message({
    message: msg,
    type: "warning"
  });
}

/**
 * @description 以成功的样式提示语
 * @param {Object} that
 * @param {Object} msg
 */
window.success = function(that, msg) {
  that.$message({
    message: msg,
    type: "success"
  });
}

/**
 * @description 以普通的样式提示语
 * @param {Object} that
 * @param {Object} msg
 */
window.info = function(that, msg) {
  that.$message({
    message: msg,
    type: "info"
  });
}



/**
 * @description 接口返回接口验证是否成功
 * @param {Object} that
 * @param {Object} res
 */
window.Authentication = function(that, res) {
  if (res.data.code != 200) {
    that.fullscreenLoading = false;
    if (res.data.message.indexOf("登录") >= 0) {
      that.$RemoveKey();
      var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
      setTimeout(function() {
        if (dd.env.platform !== "notInDingTalk") {
          that.$router.push({
            path: '/DingDing',
            query: {
              url: that.$router.history.current.fullPath
            },
          });
        } else {
          if (fullPath.indexOf("login?url=") < 0) {
            that.$router.push({
              path: '/login',
              query: {
                url: that.$router.history.current.fullPath
              },
            });
          } else {
            that.$router.push({
              path: that.$router.history.current.fullPath,
            });
          }
        }
        return false;
      }, 1500);
      return false;
    }
    return false;
  }
}



var isTokenFlag = true;
/**
 * @description 前端登录的token是否存在
 * @param {Object} that
 */
window.IsToken = function(that) {
  // var accessToken = that.$GetKey(); //sessionStorage.getItem('accessToken');
  let accessToken = that.$GetKey('accessToken');
  try {
    accessToken = JSON.parse(accessToken);
  } catch (error) {
    accessToken = accessToken;
  }


  if (isTokenFlag) {
    isTokenFlag = false;
    setTimeout(() => {
      isTokenFlag = true;
    }, 100);

    if (regNull(accessToken)) {
      // if (accessToken == null || typeof(accessToken) == "undefined" || accessToken == "" || accessToken == "undefined") {
      that.$RemoveKey();
      warning(that, "登录失效，请重新登陆！");
      var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
      setTimeout(function() {
        if (dd.env.platform !== "notInDingTalk") {
          that.$router.push({
            path: '/DingDing',
            query: {
              url: that.$router.history.current.fullPath
            },
          });
        } else {
          if (fullPath.indexOf("login?url=") < 0) {
            that.$router.push({
              path: '/login',
              query: {
                url: that.$router.history.current.fullPath
              },
            });
          } else {
            that.$router.push({
              path: that.$router.history.current.fullPath,
            });
          }
        }
        return false;
      }, 1500);
      return false;
    } else {
      return accessToken.accessToken;
    }
  } else {
    return accessToken.accessToken;
  }

}


// 以get方式访问接口，查询
window.ajaxGet = function(that, myUrl, data, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var getUrl = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    that.$axios.get(
      getUrl, {
        params: data,
        headers: {
          'Authorization': "Bearer " + token,
        }
      }
    ).then(res => {
      Authentication(that, res);
      if (res.data.success == true) {
        callback(res.data.data);
      } else {
        warning(that, res.data.message);
        recordLog(that, 3, 'GET', res.data.message, myUrl, res.data, data); // 记录日志
      }
    }).catch(err => {
      that.fullscreenLoading = false;
      tipFont(that, err, myUrl, 'GET');
    });
  } else {
    callback(null);
  }
};

//以get方式导出文件
//fileName要全称，包括扩展名
window.ajaxExport = function(that, myUrl, data, fileName, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var getUrl = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    that.$axios.get(
      getUrl, {
        params: data,
        headers: {
          'Authorization': "Bearer " + token,
        },
        'responseType': "blob"
      },

    ).then(res => {
      // 处理返回的文件流
      //主要是将返回的data数据通过blob保存成文件
      var content = res.data;
      var blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        var elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        callback(res.data.data);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    }).catch(err => {
      that.fullscreenLoading = false;
      tipFont(that, err, myUrl, 'GET');
    });
  } else {
    callback(null);
  }
};

//以post方式导出文件
//fileName要全称，包括扩展名
window.ajaxPostExport = function(that, myUrl, data, fileName, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var getUrl = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    that.$axios.post(
      getUrl,
      data,
      {
        headers: {
          'Authorization': "Bearer " + token,
          'accept': 'text/plain'
        },
        'responseType': "blob"
      }
    ).then(res => {
      console.log(res,'res')
      // 处理返回的文件流
      //主要是将返回的data数据通过blob保存成文件
      var content = res.data;
      var blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        var elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        callback(res.data.data);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    }).catch(err => {
      that.fullscreenLoading = false;
      tipFont(that, err, myUrl, 'GET');
    });
  } else {
    callback(null);
  }
};
window.ajaxPostToGet = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.success == true) {
        callback(res.data.data);
      } else {
        that.fullscreenLoading = false;
        warning(that, res.data.data.message);
        recordLog(that, 3, 'POST', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
}
window.ajaxPostVali = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        // 'Authorization': "Bearer ",
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      callback(res.data.data);
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};
// 以post方式访问接口，提交
window.ajaxPost = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        // 'Authorization': "Bearer ",
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
        if (!reg.test(res.data.data.message)) {
          success(that, res.data.data.message);
        } else {
          success(that, "新增成功");
        }
        callback(res.data.data);
      } else {
        that.fullscreenLoading = false;
        warning(that, res.data.data.message);
        recordLog(that, 3, 'POST', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};

// 以post方式访问接口，提交
window.ajaxPostError = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
        if (!reg.test(res.data.data.message)) {
          success(that, res.data.data.message);
        } else {
          success(that, "新增成功");
        }
        callback(res.data.data);
      } else {
        that.fullscreenLoading = false;
        callback(res.data.data);
      }
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      if (res.data.message.indexOf("参数验证错误") >= 0) {
        // console.log(res.data.data);
      }
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl);
  });
};

// 以post方式访问接口，提交
window.ajaxPostForBase64 = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      callback(res.data.data);
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};

// 无提示交互数据
window.ajaxPostForNoTip = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        callback(res.data.data);
      } else {
        recordLog(that, 3, 'POST', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    tipFont(that, err, myUrl, 'POST');
  });
};



window.ajaxPostNoTip = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        callback(true);
      } else {
        callback(false);
        warning(that, res.data.data.message);
        recordLog(that, 3, 'POST', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      callback(false);
      that.fullscreenLoading = false;
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    tipFont(that, err, myUrl, 'POST');
  });
};


//上传
window.ajaxUpload = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      },
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.data.result == true) {
      success(that, res.data.data.message);
      callback(res.data.data);
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.data.message);
      recordLog(that, 3, 'POST', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
    callback(null);
  });
};

window.ajaxImport = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      },
      'Content-Type': 'multipart/form-data'
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.data.result == true) {
      success(that, res.data.data.message);
      callback(res.data.data);
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.data.message);
      recordLog(that, 3, 'POST', res.data.data.message, myUrl, null, null); // 记录日志
      callback(res.data.data);
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};


// 系統登录
window.ajaxLogin = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  that.$axios.post(
    url,
    data,
  ).then(res => {
    callback(res);
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};

window.ajaxRefreshToken = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  that.$axios.post(
    url,
    data,
  ).then(res => {
    callback(res);
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'POST');
  });
};
// 以get方式访问接口，查询
window.ajaxPutToGet = function(that, myUrl, data, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var url = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    that.$axios.put(
      url,
      data, {
        headers: {
          'Authorization': "Bearer " + token,
        }
      }
    ).then(res => {
      Authentication(that, res);
      if (res.data.success == true) {
        callback(res.data.data);
      } else {
        warning(that, res.data.message);
        recordLog(that, 3, 'GET', res.data.message, myUrl, res.data, data); // 记录日志
      }
    }).catch(err => {
      that.fullscreenLoading = false;
      tipFont(that, err, myUrl, 'GET');
    });
  } else {
    callback(null);
  }
};
//以put方式访问接口，修改
window.ajaxPut = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.put(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
        if (!reg.test(res.data.data.message)) {
          success(that, res.data.data.message);
        } else {
          success(that, "编辑成功");
        }
        callback(res.data.data);
      } else {
        that.fullscreenLoading = false;
        warning(that, res.data.data.message);
        recordLog(that, 3, 'PUT', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      that.fullscreenLoading = false;
      warning(that, res.data.message);
      recordLog(that, 3, 'PUT', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'PUT');
  });
};

//以delete方式访问接口,删除
window.ajaxDelete = function(that, myUrl, data, callback) {
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.delete(
    url, {
      data,
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true) {
        success(that, res.data.data.message);
        callback(res.data.data);
      } else {
        that.fullscreenLoading = false;
        warning(that, res.data.data.message);
        recordLog(that, 3, 'DELETE', res.data.data.message, myUrl, null, null); // 记录日志
      }
    } else {
      warning(that, res.data.message);
      recordLog(that, 3, 'DELETE', res.data.message, myUrl, res.data, data); // 记录日志
    }
  }).catch(err => {
    that.fullscreenLoading = false;
    tipFont(that, err, myUrl, 'DELETE');
  });
};



/**
 * @description 以错误的样式显示提示语
 * @param {Object} that
 * @param {Object} msg
 */
window.error = function(that, msg) {
  that.$message({
    message: msg,
    type: "error"
  });
}

/**
 * @description 处理错误异常信息
 * @param {Object} that
 * @param {Object} objErrFont
 */
window.tipFont = function(that, objErrFont, apiUrl, objType) {
  if (objErrFont.type == 'api') {
    // error(that, '网络错误：'+objErrFont.message+' 【错误接口：'+apiUrl+'】');
    error(that, '错误：' + objErrFont.message);
  } else {
    // console.log('系統错误：' + objErrFont.message + '  【错误信息：' + objErrFont.stack.toString() + '】');
    error(that, '错误：' + objErrFont.message);
    recordLog(that, 4, objType, objErrFont.message, apiUrl, objErrFont.stack.toString(), null);
  }
};



/**
 * @description 记录错误日志到数据库
 * @param {Object} that
 * @param {Object} objStatus 错误类型。3：后端反馈错误日志，4：前端系統错误
 * @param {Object} objType 调用的方法
 * @param {Object} objMessage 错误信息
 * @param {Object} objApi 接口
 */
window.recordLog = function(that, objStatus, objType, objMessage, objApi, objData, objValue) {
  let errInfo = `<div><b>浏览器：</b>${JSON.stringify(onBrowserType())}</div><div><b>请求方法：</b>${objType}</div>`;

  if (!regNull(objApi)) {
    errInfo += `<div><b>接口：</b>${objApi}</div>`;
  }

  if (!regNull(objData)) {
    if (!regNull(objData.data)) {
      errInfo += `<div><b>错误信息：</b></div>`;

      Object.keys(objData.data).forEach((item, index) => {
        errInfo += `<div class="ml-5"><b>${item}：</b>${ Object.values(objData.data)[index]}</div>`;
      });

      if (!regNull(objValue)) {
        errInfo += `<div><b>参数：</b>${ JSON.stringify(objValue)}</div>`;
      }

    } else {
      errInfo += `<div><b>错误信息：${objData.message}</b></div>`;
    }
  }

  let tpParams = {
    message: '【Web】' + objMessage,
    logType: objStatus,
    url: window.location.href,
    stackTrace: errInfo,
  }

  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + '/api/log/postlog';
  // var token = IsToken(that);
  that.$axios.post(
    url,
    tpParams
    // tpParams, {
    //   headers: {
    //     'Authorization': "Bearer " + token,
    //   }
    // }
  ).then(res => {}).catch(err => {});
};



/**
 * @@description 显示浏览器版本
 */
window.onBrowserType = function() {

  // 正则表达式方式来判定user-agent字符串，得到当前访问浏览器（http代理）的版本
  var userAgent = navigator.userAgent
  var rMsie = /(msie\s|trident.*rv:)([\w.]+)/
  var rFirefox = /(firefox)\/([\w.]+)/
  var rOpera = /(opera).+version\/([\w.]+)/
  var rChrome = /(chrome)\/([\w.]+)/
  var rSafari = /version\/([\w.]+).*(safari)/
  var ua = userAgent.toLowerCase()
  var match = rMsie.exec(ua)

  if (match != null) {
    return {
      browser: 'IE',
      version: match[2] || '0'
    }
  }
  match = rFirefox.exec(ua)
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    }
  }
  match = rOpera.exec(ua)
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    }
  }
  match = rChrome.exec(ua)
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    }
  }
  match = rSafari.exec(ua)
  if (match != null) {
    return {
      browser: match[2] || '',
      version: match[1] || '0'
    }
  }
  if (match == null) {
    return {
      browser: '',
      version: '0'
    }
  }
}






window.routerPath = function(that, path, need, val, ...values) { // 路由地址 要传的建 要传的值  报保存的值
  let arr = [];
  if (typeof need == "string") { // 如果是单个值就对应传入
    arr[need] = val;
  } else if (typeof need == "object" && need != null) { //  否则传个对应对象给他    其余的值自己传入
    for (const key in need) {
      if (Array.isArray(need[key])) {
        let getData = need[key][0] + "," + need[key][1];
        need[key] = getData;
      }
    }
    arr = need;
  }

  values.forEach(element => {
    if (that.$route.query[element]) {
      if (need != null && typeof(need) == "string" && need.toLowerCase() == "pagesize" && element.toLowerCase() ==
        "pagenumer") {
        arr["PageNumer"] = 1;
      } else {
        arr[element] = that.$route.query[element];
      }
    }
  });
  arr["path"] = encodeURI(path);
  that.$router.replace({
    path: '/redirect',
    query: arr
  });
  // that.$router.push({
  //   path: path,
  //   query: arr
  // }); // 路由跳转变值
};


window.paging = function(that, callback, ...value) { // 根据路由取值
  let arr = []

  if (JSON.stringify(that.$route.query) == '{}') {
    var PageNumer = null
    var PageSize = null
    arr.PageNumer = PageNumer;
    arr.PageSize = PageSize;
    callback(arr);
  } else {
    if (that.$route.query.PageNumer == undefined) { //  判断 防止页码和页数没有值
      var PageNumer = 1;
      if (that.paging.PageNumer != undefined) {
        that.paging.PageNumer = PageNumer
      }
    } else {

      var PageNumer = that.$route.query.PageNumer;
      if (that.paging.PageNumer != undefined) {
        that.paging.PageNumer = PageNumer;
      }

    }
    if (that.$route.query.PageSize == undefined) {

      var PageSize = 10
      if (that.paging.PageSize != undefined) {
        that.paging.PageSize = 10
      }

    } else {

      var PageSize = that.$route.query.PageSize
      if (that.paging.PageSize != undefined) {
        that.paging.PageSize = PageSize * 1
      }

    }

    arr.PageNumer = PageNumer;
    arr.PageSize = PageSize;

    value.forEach(element => {
      let router = that.$route.query[element];
      if (!router && router != 0) {
        router = null
      }
      arr[element] = router
    });
    callback(arr);

  }
}

// 返回
window.back = function(that) {
  var url = that.$route.query.url;
  if (url == null || typeof(url) == "undefined" || url == "") {
    window.history.go(-1);
  } else {
    that.$router.push(url);
  }

}

window.setInt = function(val) {
  var first = "";
  if (val == null || typeof(val) == "undefined") {
    val = "";
  }
  if (val != "") {
    first = val[0];
  }
  val = val.toString();
  val = val.replace(/[^\d.]/g, "");
  val = val.replace(/\.{1,}/g, "");
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入5个小数

  if (val.indexOf(".") < 0 && val != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    val = parseInt(val);
  }
  if (first == "-") {
    val = 0 - val;
  }
  return val;
}
window.setTwoFloat = function(val) {
  var first = "";
  if (val == null || typeof(val) == "undefined") {
    val = "";
  }
  if (val != "") {
    first = val[0];
  }

  val = val.toString();
  val = val.replace(/[^\d.]/g, "");
  val = val.replace(/\.{2,}/g, ".");
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //最多输入5个小数
  if (val.indexOf(".") < 0 && val != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    val = parseFloat(val);
  }

  if (first == "-") {
    val = 0 - val;
  }

  return val;
}

//设置两位小数，自动补齐 默认保留2位小数点
window.setTwoDecimal = function(val, _iFixed) { // val:需要转换的值 ,_iFixed需要保留的小数点位数(默认2位)

  if (val == null || isNaN(val) || val == '') {
    return '';
  }
  if (parseFloat(val + "") == 0) {
    return val;
  }
  _iFixed = setInt(_iFixed);
  if (_iFixed == null || isNaN(_iFixed) || _iFixed == '') {
    _iFixed = 2;
  }
  return parseFloat(val).toFixed(_iFixed);
  //return moneySwitch(parseFloat(val),2);
};


window.moneySwitch = function(money, precision) { //precision是需要精确的位数，如百分位就是2
  var result = 0;
  //先进行一个千分位的四舍五入，保证3.0999这种情况在保留一位小数的时候能是对的，这一位可以这么做没什么问题
  var money = parseFloat(money).toFixed(3);
  try {
    var int_part = money.split(".")[0], //小数点前的整数
      point_num = money.split(".")[1], //取小数点后面的小数
      precision_num = point_num[3 - precision];
    if (precision_num > 4) { //五入的情况
      if (precision == 1) {
        point_num = parseInt(point_num) + 10 + "";
        if (point_num.length > 3) { //说明往整数位进1
          int_part = parseInt(int_part) + 1 + "";
          point_num = point_num[1] + point_num[2];
        } else {
          point_num = point_num[0] + point_num[1];
        }
        result = parseFloat(int_part + "." + point_num);
      } else if (precision == 2) {
        point_num = parseInt(point_num) + 100 + "";
        if (point_num.length > 3) { //说明往整数位进1
          int_part = parseInt(int_part) + 1 + "";
          point_num = point_num[1];
        } else {
          point_num = point_num[0];
        }
        result = parseFloat(int_part + "." + point_num);
      } else if (precision == 3) {
        int_part = parseInt(int_part) + 1 + "";
        point_num = 0;
      }
      result = parseFloat(int_part + "." + point_num);
    } else { //四舍的情况
      if (precision == 1) {
        point_num = point_num[0] + point_num[1];
      } else if (precision == 2) {
        point_num = point_num[0];
      } else if (precision == 3) {
        point_num = 0;
      }
      result = parseFloat(int_part + "." + point_num);
    }
  } catch (e) {
    return parseFloat(money).toFixed(2); //如果过程中有出错就tofixed代替为解决
  }
  return result.toFixed(2);
};


//去除数组内的重复
window.onArrUnique = function(arr) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
}
// 专业数据处理
window.onCascaderData = function(_this, obj) {
  let iCount = 0;
  //获取院部数据,去除重复性
  let arrCollege = onCascaderCollege(_this, obj);
  arrCollege.filter(item => {
    _this.$set(
      _this.optMajor, iCount, {
        value: item.value,
        label: item.label,
        //获取专业数据
        children: onCascaderChildren(_this, obj, item.value),
      }
    );
    _this.optMajor.splice();
    iCount++;
  });
}
// 生成院部临时数组
window.onCascaderCollege = function(_this, obj) {
  let iCount = 0;
  let arr = [];
  obj.filter(item => {
    _this.$set(
      arr, iCount, {
        value: item.collegeId,
        label: item.college,
      },
    );
    arr.splice();
    iCount++;
  });
  return onArrUnique(arr);
}
// 制作专业临时数组
window.onCascaderChildren = function(_this, obj, _type) {
  let iCount = 0;
  let arr = [];
  obj.filter(item => {
    if (item.collegeId === _type) {
      _this.$set(
        arr, iCount, {
          value: item.majorId,
          label: item.name,
        }
      );
      arr.splice();
      iCount++;
    }
  });
  return arr;
}



/*
        objDate:时间
        sign:日期分隔符,默认'-'
        dateType:输出格式,默认'YYYY-MM-DD'
      */
window.dateFormat = function(objDate, sign, dateType) {
  //如果没有传递符号，给一个默认的符号
  if (!sign) {
    sign = '-';
  }
  if (!dateType) {
    dateType = 'YYYY-MM-DD';
  }
  objDate = new Date(objDate);
  //获取d里面年月日时分秒
  let year = objDate.getFullYear(),
    month = objDate.getMonth() + 1,
    sun = objDate.getDate(),
    hours = objDate.getHours(),
    minutes = objDate.getMinutes(),
    seconds = objDate.getSeconds();

  switch (dateType.toUpperCase()) {
    case 'YYYY': //年
      return year;
    case 'YYYY-MM': //年月
      return year + sign + mendTimeZero(month);
    case 'YYYY-MM-DD': //年月日
      return year + sign + mendTimeZero(month) + sign + mendTimeZero(sun);
    case 'YYYY-MM-DD HH':
      return year + sign + mendTimeZero(month) + sign + mendTimeZero(sun) + ' ' + mendTimeZero(hours);
    case 'YYYY-MM-DD HH:MM':
      return year + sign + mendTimeZero(month) + sign + mendTimeZero(sun) + ' ' + mendTimeZero(hours) +
        ':' + mendTimeZero(minutes);
    default:
      return year + sign + mendTimeZero(month) + sign + mendTimeZero(sun) + ' ' + mendTimeZero(hours) + ':' +
        mendTimeZero(minutes) + ':' + mendTimeZero(seconds);
  }
}
// 10内数字前面加0
window.mendTimeZero = function(num) {
  return (num = num < 10 ? '0' + num : num)
}
// 确认绑定值是否有值
window.fieldNoValue = function(obj) {
  if (obj == null || typeof(obj) == "undefined" || obj == '') {
    return '暂无数据';
  }
  return obj;
};
/// 判断性别 true:男 | false 女
window.isGender = function(_value) {
  if (_value != null && _value != '' && _value.indexOf('男') >= 0) {
    return true;
  } else {
    return false;
  }
};

// 验证手机号码 true:错 false:对
window.regMobilePhone = function(value) {
  var reg = /^1\d{10}$/;
  if (value != 0 && !reg.exec(value)) {
    return true;
  } else {
    return false;
  }
};
// 验证邮箱 true:错 false:对
window.regMail = function(value) {
  var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  if (value != 0 && !reg.exec(value)) {
    return true;
  } else {
    return false;
  }
}
// 验证身份证
window.regIDCard = function(value) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (value != 0 && !reg.exec(value)) {
    return true;
  } else {
    return false;
  }
};
// 正整数
window.regInt = function(value) {
  var reg = /^[1-9]\d*$/;
  if (value != 0 && !reg.exec(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * @description 常规分数格式
 * @param {Object} value
 */
window.regFraction = function(value) {
  var reg = /^(\-)?\d+(\.[0-9]{1,2})?$/;
  if (value != 0 && !reg.exec(value)) {
    return true;
  } else {
    return false;
  }
}


/**
 * 数据转换为树形（递归），示例：toTreeByRecursion(source, 'id', 'parentId', null, 'children')
 * @param {Array} source 数据
 * @param {String} idField 标识字段名称
 * @param {String} parentIdField 父标识字段名称
 * @param {Any} parentIdNoneValue 父级标识空值
 * @param {String} childrenField 子节点字段名称
 * @param {Object} treeOption tree树形配置
 */
window.toTreeByRecursion = function(
  source = [],
  idField = 'id',
  parentIdField = 'parentId',
  parentIdNoneValue = '',
  childrenField = 'children',
  treeOption = undefined
) {
  const treeOptions = {
    enable: false, // 是否开启转tree插件数据
    keyField: 'key', // 标识字段名称，默认为key
    valueField: 'value', // 值字段名称，默认为value
    titleField: 'title', // 标题字段名称，默认为title

    keyFieldBind: 'id', // 标识字段绑定字段名称，默认为id
    valueFieldBind: 'id', // 值字段名称绑定字段名称，默认为id
    titleFieldBind: 'name' // 标题字段名称绑定字段名称，默认为name
  }
  // 合并tree树形配置
  if (treeOption) {
    Object.assign(treeOptions, treeOption)
  }

  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(source))
  return cloneData.filter(parent => {
    // 返回每一项的子级数组
    const branchArr = cloneData.filter(child => parent[idField] === child[parentIdField])

    // 绑定tree树形配置
    if (treeOptions.enable) {
      branchArr.map(child => {
        child[treeOptions.keyField] = child[treeOptions.keyFieldBind]
        child[treeOptions.valueField] = child[treeOptions.valueFieldBind]
        child[treeOptions.titleField] = child[treeOptions.titleFieldBind]
        return child
      })
    }

    // 如果存在子级，则给父级添加一个children属性，并赋值，否则赋值为空数组
    if (branchArr.length > 0) {
      parent[childrenField] = branchArr
    } else {
      parent[childrenField] = []
    }

    // 绑定tree树形配置
    if (treeOptions.enable) {
      parent[treeOptions.keyField] = parent[treeOptions.keyFieldBind]
      parent[treeOptions.valueField] = parent[treeOptions.valueFieldBind]
      parent[treeOptions.titleField] = parent[treeOptions.titleFieldBind]
    }

    return parent[parentIdField] === parentIdNoneValue; // 返回第一层
  })
}

window.checkTime = function(i) {
  var result = i + '';
  if (i < 10) {
    result = '0' + i;
  }
  return result;
}


let moment = require("moment");
window.timeTransform = function(time, format) {
  if (time === "") {
    return "";
  }
  if (format == null || format == "") {
    format = "YYYY-MM-DDTHH:mm:ss.SSS";
  }
  var result = moment(time).format(format);
  return result;
}

window.getTimeID = function() {
  let time = new Date();
  let format = "YYYYMMDDHHmmssSSS";
  var result = moment(time).format(format);
  return result;
}

/*
  例 moment('2010-10-20').isBefore('2010-10-21'); ==》 true
  start:2010-10-20 需要判断时间
  end:2010-10-21 当前时间
  true: 过时，false 未过时
*/
window.compareDate = function(start, end) {
  return moment(start).isBefore(end);
}


window.Join = function(data) {
  if (data != null) {
    if (Array.isArray(data)) {
      if (data.length > 0) {
        var val = "";
        for (var it of data) {
          if (val == "") {
            val = it;
          } else {
            val += "," + it;
          }
        }
        return val;
      }
    }
    return data;

  }
}




/**
 * 头像
 * @param {String} _photo 头像，
 * @param {String} _gender 性别，
 */
window.onGetAvatorUrl = function(_this, _photo, _gender) {
  let tempUrl = '';
  if (_photo == null || typeof(_photo) == "undefined" || _photo == '') {
    if (isGender(_gender)) {
      // 男
      tempUrl = require('@/assets/image/defaultAvatar_Male.jpg');
    } else {
      // 女
      tempUrl = require('@/assets/image/defaultAvatar_Female.jpg');
    }
  } else {
    tempUrl = _this.$ServerUrl() + _photo;
  }
  return tempUrl;
}

/**
 * @description 注销
 * @param {Object} that
 */
window.WebLogout=function(that){
  that.$RemoveKey();
  setTimeout(function() {
    if (dd.env.platform !== "notInDingTalk") {
      that.$router.push({
        path: '/Logout',
        query: {
          url: that.$router.history.current.fullPath
        },
      });
    } else {
      that.$router.push({
        path: '/login',
      });
    }
    return false;
  }, 1500);
}


/**
 * 判断是否空值 true:是空值
 * @param {String} obj 需要判断的值
 */
window.regNull = function(obj) {
  if (obj != null && typeof(obj) != "undefined" && obj != '') {
    return false;
  } else {
    return true;
  }
}
/**
 * @description 判断数组是否为空值，true：空值
 * @param {Object} obj
 */
window.regNullArray = function(obj) {
  if (obj != null && obj.length != 0) {
    return false;
  } else {
    return true;
  }
}

window.delHtml = function(obj) {
  if (regNull(obj)) {
    return obj;
  } else {
    return obj.replace(/<[^>]+>/g, "").replace(/&nbsp;/ig, "");
  }
}

/**
 * @description 计算列表k 操作列宽
 * @param {Object} that
 * @param {Object} arrData
 */
window.onGetWidth = function(that, arrData) {
  let tpNumber = 0;
  arrData.forEach(function(item) {
    if (item) {
      tpNumber = tpNumber + 80;
    }
  });
  if (tpNumber < 100) {
    return 100;
  } else {
    return tpNumber;
  }
}



/**
 * @description 线型数据转换树型数据
 * @param {Object} a 数据
 * @param {Object} idStr id名
 * @param {Object} pidStr pid名
 * @param {Object} chindrenStr chindren名
 */
window.onGetJsonToTree = function(a, idStr, pidStr, chindrenStr) {
  var r = [],
    hash = {},
    id = idStr,
    pid = pidStr,
    children = chindrenStr,
    i = 0,
    j = 0,
    len = a.length;
  for (; i < len; i++) {
    hash[a[i][id]] = a[i];
  }
  for (; j < len; j++) {
    var aVal = a[j],
      hashVP = hash[aVal[pid]];
    if (hashVP) {
      !hashVP[children] && (hashVP[children] = []);
      hashVP[children].push(aVal);
    } else {
      r.push(aVal);
    }
  }
  return r;
}

/**
 * @description 将树型数据转换为线型数组
 * @param {Object} arr
 */
window.onGetTreeConvertList = function(arr) {
  let res = []
  arr.forEach((item) => {
    res.push(item)
    if (item.children) {
      res.push(...onGetTreeConvertList(item.children))
    }
    //delete item.children;
  })
  return res;
}


/**
 * @description 简易生成随机GUID
 */
window.onGetRandomGUID = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  // // 输出类似于 "9fd9a3e8-ae6f-490d-8a8e-6e5d34faef69" 的GUID
}







/**
 * @description 计算活动状态名称 和 按钮显示
 * @param {Object} _isSignUp 是否需要报名
 * @param {Object} _isStatus 活动当前状态
 * @param {Object} _isImportScope 是否导入报名范围
 * @param {Object} _isUpload 是否上传
 * @param {Object} _valSignupStart 报名开始时间
 * @param {Object} _valSignupEnd 报名结束时间
 * @param {Object} _valStarTime 活动开始时间
 * @param {Object} _valEndTime 活动结束时间
 * @param {object} _valUploadTime 学生上传文件截止时间
 * @param {Object} _isAdmin 是否为审核员
 * @param {object} _isMyClass 是否可以代报名
 * ////////////////////////////////////////////学生
 * @param {object} _isStudent 是否为学生
 * @param {object} _isJoin 是否可以报名(当报名成功后，变成为false)
 * @param {object} _valRosterStatus 名单状态 0:未报名  1：待审核 2：审核不通过 3：审核通过
 * @param {object} _isSignIn 是否需要签到
 * @param {object} _isStudentSignUp 学生签到情况
 * @param {object} _isAudit 是否需要报名审核
 *
 */
window.onGetActivityStatus = function(_isSignUp, _isStatus, _isImportScope, _isUpload, _valSignupStart, _valSignupEnd,
  _valStarTime, _valEndTime, _valUploadTime, _isAdmin, _isMyClass, _isStudent, _isJoin, _valRosterStatus, _isSignIn,
  _isStudentSignUp, _isAudit, ) {

  // 活动各状态数组
  let arrStatus = ['报名未开始', '开始报名', '报名结束', '已确认名单', '待确认名单', '进行中', '未计入综合分', '活动已结束', '待学生上传文件', '上传活动相关图片',
    '活动未开始'
  ];
  let arrStudentStatus = ['无需报名', '未报名', '待审核', '审核不通过', '已报名', '未参加', '已参加', '等待报名', '已签到', '未签到', '统计中', '非报名范围内'];
  let arrReturn = {
    isBtnEdit: false, // 编辑
    isBtnDelete: false, // 删除
    isBtnImportScope: false, // 导入报名范围
    isBtnConfirm: false, // 确认名单
    isBtnRevoke: false, // 撤消
    isBtnAwards: false, // 评奖
    isBtnFinally: false, // 最后计入综合分
    isBtnReplace: false, // 是否可以代报名
    isBtnShow: true, // 是否显示操作
    statusName: '待报名', // 状态名称

    isBtnCancel: false, // 是否取消报名
    isBtnJoin: false, // 是否显示立即报名
    isBtnUpload: false, // 是否上传文件
    isBtnSignIn: false, // 是否显示签到按钮
    statusStuName: '', // 学生报名情况
  }
  arrReturn.isBtnShow = _isAdmin;

  let icount = 0;

  // 判断活动是否已撤消
  if (_isStatus == -1) {
    arrReturn.statusName = '已撤销'; //报名未开始
    arrReturn.statusStuName = '已撤销'; //报名未开始
  } else {
    // 判断是否报名，需要报名为有流程报名
    if (_isSignUp) {
      /*有流程活动*/

      // 报名时间未开始
      if (!compareDate(timeTransform(_valSignupStart), timeTransform(new Date()))) {

        arrReturn.statusName = arrStatus[0]; //报名未开始
        arrReturn.statusStuName = _isJoin ? arrStudentStatus[7] : arrStudentStatus[11]; //报名未开始
        arrReturn.isBtnEdit = true;
        arrReturn.isBtnDelete = true;
        arrReturn.isBtnReplace = false;
        arrReturn.isBtnImportScope = _isImportScope;





      } else if (compareDate(timeTransform(_valSignupStart), timeTransform(new Date())) && !compareDate(timeTransform(
          _valSignupEnd), timeTransform(new Date()))) {
        // 报名时间开始 报名时间未结束


        arrReturn.statusName = arrStatus[1]; //报名开始
        arrReturn.isBtnRevoke = true;
        arrReturn.isBtnConfirm = _isAudit; // 判断是否需要审核
        arrReturn.isBtnReplace = _isMyClass;

        // 判断是否为学生角色
        if (_isStudent) {
          // 判断是否 可以报名


          // 根据审核状态显示
          switch (_valRosterStatus) {
            case 0:
              arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[11]; //未报名
              arrReturn.isBtnJoin = _isJoin;
              break;
            case 1:
              arrReturn.statusStuName = arrStudentStatus[2]; //待审核
              arrReturn.isBtnCancel = true;
              break;
            case 2:
              arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
              break;
            case 3:
              arrReturn.statusStuName = arrStudentStatus[4]; //已报名
              arrReturn.isBtnCancel = true;
              break;
          }

        }



      } else if (compareDate(timeTransform(_valSignupEnd), timeTransform(new Date())) && !compareDate(timeTransform(
          _valStarTime), timeTransform(new Date()))) {
        // 报名时间结束，活动时间未开始

        if (_isStatus == 1) { // 1=>待报名 等于 未确认名单
          arrReturn.statusName = arrStatus[2]; //报名结束
          arrReturn.statusStuName = _isAudit ? arrStudentStatus[2] : arrStudentStatus[4];

        } else {
          // 学生看到的活动状态有所不同
          arrReturn.statusName = _isStudent ? arrStatus[2] : arrStatus[3]; //名单已确认

          if (_isStudent) {


            // _isAudit 判断是否需要审核
            switch (_valRosterStatus) {
              case 0:
                arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[
                  11]; // 如果状态为0 进行判断是否可以报名，可以报名为 未报名
                break;
              case 1:
                arrReturn.statusStuName = arrStudentStatus[2]; //待审核
                break;
              case 2:
                arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
                break;
              case 3:
                arrReturn.statusStuName = arrStudentStatus[4]; //审核通过
                break;
            }

          }
        }
        arrReturn.isBtnConfirm = _isAudit;
        arrReturn.isBtnRevoke = true;


      } else if (compareDate(timeTransform(_valStarTime), timeTransform(new Date())) && !compareDate(timeTransform(
          _valEndTime), timeTransform(new Date()))) {
        // 活动时间开始
        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[2] : arrStatus[4]; //待确认名单
          arrReturn.isBtnConfirm = _isAudit;
          arrReturn.isBtnRevoke = true;
          arrReturn.statusStuName = _isAudit ? arrStudentStatus[2] : arrStudentStatus[4];
        } else {
          arrReturn.statusName = arrStatus[5]; //进行中


          // 判断是否为学生角色
          if (_isStudent) {

            // 根据学生报名状态进行判断
            switch (_valRosterStatus) {
              case 0: // 为未报名
                arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[
                  11]; // 如果状态为0 进行判断是否可以报名，可以报名为 未报名
                break;
              case 3:
                // 判断是否要活动签到
                if (_isSignIn) {
                  arrReturn.isBtnSignIn = _isStudentSignUp ? false : true; // 签到按钮
                  arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[8] : arrStudentStatus[9];
                } else {
                  arrReturn.statusStuName = arrStudentStatus[4]
                }
                break;
              default: // 其他状态都为 审核不通过
                arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
                break;
            }
          }
        }
      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && !_isUpload) {
        // 活动时间结束，无学生上传文件
        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[2] : arrStatus[4]; //待确认名单
          arrReturn.isBtnConfirm = _isAudit;
          arrReturn.isBtnRevoke = true;
          arrReturn.statusStuName = _isAudit ? arrStudentStatus[2] : arrStudentStatus[4];
        } else if (_isStatus == 2) {
          arrReturn.statusName = _isStudent ? arrStatus[7] : arrStatus[6]; //未计入综合分
          arrReturn.isBtnAwards = true;
          arrReturn.isBtnFinally = true;
        } else {
          arrReturn.statusName = arrStatus[7]; //活动已结束
          arrReturn.isBtnAwards = true;
          arrReturn.isBtnFinally = true;
        }


        // 判断是否为学生角色
        if (_isStudent && _isStatus > 1) {
          // 根据学生报名状态进行判断
          switch (_valRosterStatus) {
            case 0: // 为未报名
              arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[
                11]; // 如果状态为0 进行判断是否可以报名，可以报名为 未报名
              break;
            case 3:
              // 判断是否要活动签到
              if (_isSignIn) {
                arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[8] : arrStudentStatus[9];
              } else {
                arrReturn.statusStuName = arrStudentStatus[6]; //已参加
              }
              break;
            default: // 其他状态都为 审核不通过
              arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
              break;
          }
        }


      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && _isUpload && !compareDate(
          timeTransform(_valUploadTime), timeTransform(new Date()))) {
        // 活动时间结束，学生上传文件截止时间未结束时
        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[2] : arrStatus[4]; //待确认名单
          arrReturn.isBtnConfirm = _isAudit;
          arrReturn.isBtnRevoke = true;
          arrReturn.statusStuName = _isAudit ? arrStudentStatus[2] : arrStudentStatus[4];
        } else {
          arrReturn.statusName = _isStudent ? arrStatus[9] : arrStatus[8]; //待学生上传文件
          arrReturn.isBtnAwards = true;


          // 判断是否为学生角色
          if (_isStudent && _isStatus > 1) {
            // 根据学生报名状态进行判断
            switch (_valRosterStatus) {
              case 0: // 为未报名
                arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[
                  11]; // 如果状态为0 进行判断是否可以报名，可以报名为 未报名
                break;
              case 3:
                // 判断是否要活动签到
                if (_isSignIn) {
                  arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[8] : arrStudentStatus[9];
                  arrReturn.isBtnUpload = _isStudentSignUp; // 签到过的学生才能上传文件
                } else {
                  arrReturn.statusStuName = arrStudentStatus[6]; //已参加
                  arrReturn.isBtnUpload = true; // 签到过的学生才能上传文件
                }
                break;
              default: // 其他状态都为 审核不通过
                arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
                break;
            }
          }


        }
      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && _isUpload && compareDate(
          timeTransform(_valUploadTime), timeTransform(new Date()))) {
        // 活动时间结束，学生上传文件截止时间已结束
        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[2] : arrStatus[4]; //待确认名单
          arrReturn.isBtnConfirm = _isAudit;
          arrReturn.isBtnRevoke = true;
          arrReturn.statusStuName = _isAudit ? arrStudentStatus[2] : arrStudentStatus[4];
        } else if (_isStatus == 2) {
          arrReturn.statusName = _isStudent ? arrStatus[7] : arrStatus[6]; //未计入综合分
          arrReturn.isBtnAwards = true;
          arrReturn.isBtnFinally = true;
        } else {
          arrReturn.statusName = arrStatus[7]; //活动已结束
          arrReturn.isBtnAwards = true;
          arrReturn.isBtnFinally = true;
        }


        // 判断是否为学生角色
        if (_isStudent && _isStatus > 1) {
          // 根据学生报名状态进行判断
          switch (_valRosterStatus) {
            case 0: // 为未报名
              arrReturn.statusStuName = _isJoin ? arrStudentStatus[1] : arrStudentStatus[
                11]; // 如果状态为0 进行判断是否可以报名，可以报名为 未报名
              break;
            case 3:
              // 判断是否要活动签到
              if (_isSignIn) {
                arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[6] : arrStudentStatus[5];
              } else {
                arrReturn.statusStuName = arrStudentStatus[6]; //已参加
              }
              break;
            default: // 其他状态都为 审核不通过
              arrReturn.statusStuName = arrStudentStatus[3]; //审核不通过
              break;
          }
        }
      }



    } else {
      /*不需要报名活动*/
      arrReturn.isBtnDelete = true;
      if (!compareDate(timeTransform(_valStarTime), timeTransform(new Date())) && !compareDate(timeTransform(
          _valEndTime), timeTransform(new Date()))) {
        // 活动时间 未开始，
        arrReturn.statusName = arrStatus[10]; //活动未开始
        arrReturn.statusStuName = arrStudentStatus[0]; //无需报名
        arrReturn.isBtnEdit = true;
      } else if (compareDate(timeTransform(_valStarTime), timeTransform(new Date())) && !compareDate(timeTransform(
          _valEndTime), timeTransform(new Date()))) {
        // 活动时间 开始
        arrReturn.statusName = arrStatus[5]; //进行中

        // 判断是否为学生角色
        if (_isStudent) {

          // 判断是否要活动签到
          if (_isSignIn) {
            arrReturn.isBtnSignIn = _isStudentSignUp ? false : true; // 签到按钮
            arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[8] : arrStudentStatus[9];
          } else {
            arrReturn.statusStuName = arrStudentStatus[0]; //统计中
          }

        }
      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && !_isUpload) {
        // 活动时间结束，无学生上传文件
        arrReturn.isBtnAwards = true;
        arrReturn.isBtnFinally = true;

        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[7] : arrStatus[6]; //未计入综合分
        } else {
          arrReturn.statusName = arrStatus[7]; //活动已结束
          arrReturn.isBtnDelete = false;
        }

        // 判断是否为学生角色
        if (_isStudent) {

          // 判断是否要活动签到
          if (_isSignIn) {
            // 判断学生是否签到，签到为已参加
            arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[6] : arrStudentStatus[5];
          } else {
            //通过活动状态显示 学生活动状态
            if (_isStatus == 1) {
              arrReturn.statusStuName = arrStudentStatus[0]; //统计中
            } else {
              // 判断学生是否参加了活动了
              arrReturn.statusStuName = _valRosterStatus == 3 ? arrStudentStatus[6] : arrStudentStatus[5];
            }
          }
        }
      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && _isUpload && !compareDate(
          timeTransform(_valUploadTime), timeTransform(new Date()))) {
        // 活动时间结束，学生上传文件截止时间未结束时
        arrReturn.isBtnAwards = true;
        arrReturn.statusName = _isStudent ? arrStatus[9] : arrStatus[8]; //待学生上传文件

        // 判断是否为学生角色
        if (_isStudent) {
          // 判断是否要活动签到
          if (_isSignIn) {
            // 判断学生是否签到，签到为已参加
            arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[6] : arrStudentStatus[5];
          } else {
            arrReturn.statusStuName = arrStudentStatus[0]; //统计中
          }
        }
      } else if (compareDate(timeTransform(_valEndTime), timeTransform(new Date())) && _isUpload && compareDate(
          timeTransform(_valUploadTime), timeTransform(new Date()))) {
        // 活动时间结束，学生上传文件截止时间已结束
        arrReturn.isBtnAwards = true;
        arrReturn.isBtnFinally = true;
        if (_isStatus == 1) {
          arrReturn.statusName = _isStudent ? arrStatus[7] : arrStatus[6]; //未计入综合分
        } else {
          arrReturn.statusName = arrStatus[7]; //活动已结束
          arrReturn.isBtnDelete = false;
        }
        // 判断是否为学生角色
        if (_isStudent) {
          // 判断是否要活动签到
          if (_isSignIn) {
            // 判断学生是否签到，签到为已参加
            arrReturn.statusStuName = _isStudentSignUp ? arrStudentStatus[6] : arrStudentStatus[5];
          } else {
            //通过活动状态显示 学生活动状态
            if (_isStatus == 1) {
              arrReturn.statusStuName = arrStudentStatus[0]; //统计中
            } else {
              // 判断学生是否参加了活动了
              arrReturn.statusStuName = _valRosterStatus == 3 ? arrStudentStatus[6] : arrStudentStatus[5];
            }
          }
        }
      }
    }
  }
  return arrReturn;
}
