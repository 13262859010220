import moment from 'moment/moment';

export default {
  install(Vue, options) {
    /***********************************************************************************************
        系統配置
    ************************************************************************************************/

    //Regin API地址
    /**
     * @description 后端地址
     */
    Vue.prototype.$ServerUrl = function() {
      // return 'http://localhost:5010'; //测试服务器
      // return `http://www.zhpj.net/Api`; //本地联正式服
      return `http://${window.location.host}/Api`; //正式服 记得修改版本号(vue.config中修改)
    };

    /**
     * @description 前端地址
     */
    Vue.prototype.$WebUrl = function() {
      return `http://${window.location.host}`;
    };



    /***********************************************************************************************
        Session类设置
    ************************************************************************************************/
    //Regin 保存登录凭证
    Vue.prototype.$SaveKey = function(obj, key) {
      if (key == null || typeof(key) == "undefined" || key == "") {
        localStorage.setItem("accessToken", JSON.stringify(obj));
      } else {
        localStorage.setItem(key, JSON.stringify(obj));
      }
    };
    //Endregin 保存登录凭证

    //Regin 获取登录凭证
    Vue.prototype.$GetKey = function(key) {
      if (key == null || typeof(key) == "undefined" || key == "") {
        return localStorage.getItem('accessToken');
      } else {
        return localStorage.getItem(key);
      }
    };
    //Endregin 获取登录凭证

    //注销时,清空所有缓存和凭证
    Vue.prototype.$RemoveKey = function(key) {
      if(regNull(key)){
        localStorage.clear();
        // sessionStorage.clear();
      }else{
        localStorage.removeItem(key);
      }
    }



  }
};

//判断权限
//key是页面中的权限变量
//code可以action对应的代码，也可以是action对应的url
window.isPower = function(that, key, code, callback) {
  var power = JSON.parse(that.$GetKey("actions"));
  if (
    (power.find(item => item.url != null && item.url.toUpperCase().trim() == code.toUpperCase().trim()) !=
      undefined) ||
    (power.find(item => item.code != null && item.code.toUpperCase().trim() == code.toUpperCase().trim()) !=
      undefined)
  ) {
    that.isPower[key] = true;
  }
}
